defineDs('/Components/DanskeSpil/Domain/Feature.Components.Dlo/Scripts/WinningNumbersGetNumbers', [
  'DanskeSpil/Domain/AllOrNothing/Scripts/Helpers/AllOrNothingApi',
  'DanskeSpil/Domain/Eurojackpot/Scripts/Helpers/EurojackpotApi',
  'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoApi',
  'DanskeSpil/Domain/Lotto/Scripts/Helpers/SaturdayLottoApi',
  'DanskeSpil/Domain/VikingLotto/Scripts/Helpers/VikingLottoApi',
  'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils',
], async function (AllOrNothingApi, EurojackpotApi, KenoApi, SaturdayLottoApi, VikingLottoApi, Utils) {

  const winningNumbersElement = document.querySelector('.js-winning-numbers');

  if (!winningNumbersElement) {
    return;
  }

  let games = JSON.parse(winningNumbersElement.dataset.games);
  const selectedGame = winningNumbersElement.dataset.selectedGame;

  const rearrangeArray = (games, selectedGame) => {
    // Remove the selected game from the array
    const filteredGames = games.filter((game) => game !== selectedGame);
    // Add the selected game to the beginning of the array
    return [selectedGame, ...filteredGames];
  };

  // rearrange games so that we load data for the selected game first.
  games = rearrangeArray(games, selectedGame);

  const note = {
    newWinner: winningNumbersElement.dataset.noteNewWinner,
    newWinners: winningNumbersElement.dataset.noteNewWinners,
    newMillionaire: winningNumbersElement.dataset.noteNewMillionaire,
    newMillionaires: winningNumbersElement.dataset.noteNewMillionaires,
  };

  const latestDraws = {
    allornothing: {
      drawDate: null,
      winningNumbers: [],
      getDataAttempts: 0,
    },
    eurojackpot: {
      drawDate: null,
      winningNumbers: [],
      bonusNumbers: [],
      winners: {
        millionaires: 0,
        winners: 0,
      },
      getDataAttempts: 0,
    },
    lotto: {
      drawDate: null,
      winningNumbers: [],
      bonusNumbers: [],
      winners: {
        millionaires: 0,
        winners: 0,
      },
      getDataAttempts: 0,
    },
    vikinglotto: {
      drawDate: null,
      winningNumbers: [],
      bonusNumbers: [],
      winners: {
        millionaires: 0,
        winners: 0,
      },
      getDataAttempts: 0,
    },
    keno: {
      drawDate: null,
      winningNumbers: [],
      getDataAttempts: 0,
    },
  };

  const hasDrawDate = (game) => !!latestDraws[game].drawDate;

  const hasWinners = (game) => !!latestDraws[game].winners;

  const hasNumbers = (game) => {
    const hasWinningNumbers = latestDraws[game].winningNumbers.length > 0;

    if (!hasWinningNumbers) {
      return false;
    }

    const hasBonusNumbers = !!latestDraws[game].bonusNumbers;

    if (!hasBonusNumbers) {
      return true;
    }

    return latestDraws[game].bonusNumbers.length > 0;

  };

  const hasData = (game) => hasDrawDate(game) && hasNumbers(game);

  const addNumbersToElement = (element, numbers) => {
    numbers.forEach((number) => {
      const ball = document.createElement('li');
      ball.classList.add('ds-winning-numbers__ball');
      ball.textContent = number;
      element.appendChild(ball);
    });
  };

  const setLatestDrawDate = async (game) => {
    let completedDraws;

    switch (game) {
    case 'allornothing':
      completedDraws = await AllOrNothingApi.getCompletedDrawsData();
      latestDraws[game].drawDate = completedDraws?.drawDates[0];
      break;

    case 'eurojackpot':
      completedDraws = await EurojackpotApi.getCompletedDrawDates();
      latestDraws[game].drawDate = completedDraws?.eurojackpot?.drawDates[0];
      break;

    case 'keno':
      completedDraws = await KenoApi.getCompletedDraws();
      latestDraws[game].drawDate = completedDraws?.[0].date.substring(0, 10);
      break;

    case 'lotto':
      completedDraws = await SaturdayLottoApi.getCompletedDrawDates();
      latestDraws[game].drawDate = completedDraws?.[0];
      break;

    case 'vikinglotto':
      completedDraws = await VikingLottoApi.getCompletedDrawDates();
      latestDraws[game].drawDate = completedDraws?.[0];
      break;
    }

  };

  const setNewWinners = (game, prizes) => {
    if (!prizes) return;

    prizes.map((prize) => {
      latestDraws[game].winners.winners += prize.numberOfWinners;

      if (prize.amount >= 1000000) {
        latestDraws[game].winners.millionaires += prize.numberOfWinners;
      }
    });
  };

  const setNumbers = async (game) => {
    let numbers;

    switch (game) {
    case 'allornothing':
      numbers = await AllOrNothingApi.getWinningNumbers({ drawId: latestDraws[game].drawDate });
      latestDraws[game].winningNumbers = numbers?.winningNumbers || [];
      break;

    case 'eurojackpot':
      numbers = await EurojackpotApi.getEurojackpotWinningNumbers({ drawId: latestDraws[game].drawDate });
      latestDraws[game].winningNumbers = numbers?.winningNumbers || [];
      latestDraws[game].bonusNumbers = numbers?.starNumbers || [];
      setNewWinners(game, numbers?.prizes);
      break;

    case 'keno':
      numbers = await KenoApi.getWinningNumbers({ drawId: latestDraws[game].drawDate });
      latestDraws[game].winningNumbers = numbers?.winningNumbers || [];
      break;

    case 'lotto':
      numbers = await SaturdayLottoApi.getWinningNumbersLotto({ drawId: latestDraws[game].drawDate });
      latestDraws[game].winningNumbers = numbers?.lottoSaturday?.winningNumbers || [];

      if (numbers?.lottoSaturday?.bonusNumber) {
        latestDraws[game].bonusNumbers = [numbers?.lottoSaturday?.bonusNumber];
      } else {
        latestDraws[game].bonusNumbers = [];
      }

      setNewWinners(game, numbers?.lottoSaturday?.prizes);
      break;

    case 'vikinglotto':
      numbers = await VikingLottoApi.getWinningNumbers({ drawId: latestDraws[game].drawDate });
      latestDraws[game].winningNumbers = numbers?.vikinglotto?.winningNumbers || [];

      if (numbers?.vikinglotto?.vikingNumber) {
        latestDraws[game].bonusNumbers = [numbers?.vikinglotto?.vikingNumber];
      } else {
        latestDraws[game].bonusNumbers = [];
      }

      setNewWinners(game, numbers?.vikinglotto?.prizes);
      break;
    }

  };

  const setData = async (game) => {
    const numbersElement = winningNumbersElement.querySelector(`[data-numbers="${game}"]`);

    // Do nothing if we already have added the numbers.
    if (numbersElement?.children.length > 0) return;

    if (!hasDrawDate(game)) {
      await setLatestDrawDate(game);
    }

    if (!hasNumbers(game)) {
      await setNumbers(game);
    }

    if (hasDrawDate(game)) {
      const dateElement = winningNumbersElement.querySelector(`[data-date="${game}"]`);
      dateElement.textContent = Utils.formatDateDayDateMonthYear(latestDraws[game].drawDate);
    }

    if (hasWinners(game)) {
      let txt;
      const noteElement = winningNumbersElement.querySelector(`[data-note="${game}"]`);

      const millionaires = latestDraws[game].winners.millionaires;
      const winners = latestDraws[game].winners.winners;

      if (millionaires === 1) {
        txt = note.newMillionaire;
      } else if (millionaires > 1) {
        txt = note.newMillionaires;
        txt = txt.replace('{numberOfWinners}', Utils.formatNumber(millionaires));
      } else if (winners === 1) {
        txt = note.newWinner;
      } else if (winners > 1) {
        txt = note.newWinners;
        txt = txt.replace('{numberOfWinners}', Utils.formatNumber(winners));
      }

      if (txt) {
        noteElement.innerHTML = txt;
      }
    }

    if (hasData(game)) {
      addNumbersToElement(numbersElement, latestDraws[game].winningNumbers);

      if (latestDraws[game].bonusNumbers) {
        const bonusNumbersElement = winningNumbersElement.querySelector(`[data-bonus-numbers="${game}"]`);
        addNumbersToElement(bonusNumbersElement, latestDraws[game].bonusNumbers);
      }

    } else {

      // Try up to 3 times to get the data.
      if (latestDraws[game].getDataAttempts < 3) {
        latestDraws[game].getDataAttempts++;

        setTimeout(() => {
          setData(game);
        }, 5000);
      }
    }
  };


  const initialize = async () => {
    for (let i = 0; i < games.length; i++) {
      const game = games[i];
      await setData(game);
    }
  };

  await initialize();

});
