defineDs('/Components/Common/Framework/Foundation.SharedScripts/Scripts/ContentData/PoolCountdown.js', [], function () {
  const allCountdowns = document.querySelectorAll('.js-countdown');

  allCountdowns.forEach((countdown) => {
    const countdownValue = countdown.dataset.countdown;
    const clock = new Clock(countdownValue);

    if (clock.el) {
      countdown.appendChild(clock.el);
      countdown.style.display = '';
    } else {
      // hide .js-countdown if clock.el fails
      countdown.style.display = 'none';
    }
  });

  function CountdownTracker(label, value) {
    const el = document.createElement('span');

    el.className = 'ds-countdown__piece';
    el.innerHTML = '' +
      '<b class="ds-countdown__card">' +
      '<div class="ds-countdown__top"></div>' +
      '<div class="ds-countdown__bottom"></div>' +
      '<div class="ds-countdown__back">' +
      '<div class="ds-countdown__bottom"></div>' +
      '</div>' +
      '</b>' +
      '<div class="ds-countdown__slot">' + label + '</div>';

    this.el = el;

    const top = el.querySelector('.ds-countdown__top');
    const bottom = el.querySelector('.ds-countdown__bottom');
    const back = el.querySelector('.ds-countdown__back');
    const backBottom = el.querySelector('.ds-countdown__back .ds-countdown__bottom');

    this.update = function (val) {
      val = ('0' + val).slice(-2);
      if (val !== this.currentValue) {

        if (this.currentValue >= 0) {
          back.setAttribute('data-value', this.currentValue);
          bottom.setAttribute('data-value', this.currentValue);
        }
        this.currentValue = val;
        top.innerText = this.currentValue;
        backBottom.setAttribute('data-value', this.currentValue);

        this.el.classList.remove('flip');
        this.el.offsetWidth;
        this.el.classList.add('flip');
      }
    };

    this.update(value);
  }

  // Calculation adapted from https://www.sitepoint.com/build-javascript-countdown-timer-no-dependencies/
  function getTimeRemaining(endTime) {
    const t = Date.parse(endTime) - Date.parse(new Date());
    return {
      Total: t,
      Dage: Math.floor(t / (1000 * 60 * 60 * 24)),
      Timer: Math.floor((t / (1000 * 60 * 60)) % 24),
      Minutter: Math.floor((t / 1000 / 60) % 60),
      Sekunder: Math.floor((t / 1000) % 60)
    };
  }

  // Parse date correctly in Safari v15
  function parseDate(date) {
    const parsed = Date.parse(date);
    if (!isNaN(parsed)) {
      return parsed;
    }

    return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
  }

  function Clock(countdown) {
    countdown = countdown ? new Date(parseDate(countdown)) : false;
    const updateFn = countdown ? getTimeRemaining : false;

    if (!countdown) return;
    if (!updateFn) return;

    this.el = document.createElement('div');
    this.el.className = 'ds-countdown';

    const t = updateFn(countdown);
    let trackers = {};
    let key;
    let timeInterval;

    for (key in t) {
      if (key === 'Total') {
        continue;
      }
      trackers[key] = new CountdownTracker(key, t[key]);
      this.el.appendChild(trackers[key].el);
    }

    let i = 0;

    function updateClock() {
      timeInterval = requestAnimationFrame(updateClock);

      // throttle so it's not constantly updating the time.
      if (i++ % 10) {
        return;
      }

      const t = updateFn(countdown);
      if (t.Total < 0) {
        cancelAnimationFrame(timeInterval);
        for (key in trackers) {
          trackers[key].update(0);
        }
        return;
      }

      for (key in trackers) {
        trackers[key].update(t[key]);
      }
    }

    setTimeout(updateClock, 500);
  }
});
