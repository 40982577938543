defineDs('/Components/DanskeSpil/Domain/Feature.Components/Scripts/MediaCard.js', [], function () {
  const media = document.querySelectorAll('.js-media-card');

  if (media.length) {
    const playVideoHandler = (elem) => {
      const parent = elem.target.closest('.group');
      if (!parent) return;
      const iframe = parent.querySelector('iframe');
      if (!iframe) return;
      // add class to parent element
      parent.classList.add('playing');

      // play video from vimeo
      if (iframe.src.includes('vimeo')) {
        const video = iframe.contentWindow;
        video?.postMessage('{"method":"play"}', '*');
      } else {
        // play video from mindstamp and un-mute
        iframe.src += '?autoplay=1';
      }

      // request fullscreen if mobile
      if (window.innerWidth < 768) {
        iframe.requestFullscreen();
      }
    };

    media.forEach((elem) => { elem.addEventListener('click', playVideoHandler, false); });
  }
});
